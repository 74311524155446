<template>
  <div class="vx-row mb-12">
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
      <data-table
        :responseData="responseData"
        :propsParams="params"
        :header="header"
        @reloadDataFromChild="reloadData"
      >
        <template slot="tbody">
          <vs-tr v-for="(tr, i) in responseData.creditNote" :key="i">
            <vs-td>
              <div class="flex gap-3">
                <vx-tooltip style="width: fit-content" text="Detail">
                  <vs-button
                    color="green"
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    @click="showDetail(tr)"
                  />
                </vx-tooltip>
              </div>
            </vs-td>
            <vs-td>{{ tr.Code }}</vs-td>
            <vs-td>{{ tr.Type }}</vs-td>
            <vs-td>
              Name : {{ tr.CustomerName }} <br />
              Code : {{ tr.CustomerCode }}
            </vs-td>
            <!-- <vs-td>{{ priceFormat(tr.TotalValue) }}</vs-td> -->
            <vs-td>{{ dateFormat(tr.PostingDate) }}</vs-td>
            <vs-td>{{ tr.NoteReversal }}</vs-td>
          </vs-tr>
        </template>
      </data-table>
    </div>
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <div>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              size="small"
              v-on:click="closeDetail"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <detail @close="closeDetail" :selected="selectedData" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import moment from "moment";
import detail from "../detail.vue";
import { dataTableCreditNote } from "../../../../../services/api/credit_note";
export default {
  components: {
    detail,
  },
  data() {
    return {
      responseData: {},
      selectedData: {},
      params: {
        search: "",
        length: 5,
        page: 1,
        order: "desc",
        sort: "credit_notes.updated_at",
      },
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      header: [
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
        {
          text: "Code",
          value: "Code",
        },
        {
          text: "Type",
          value: "Type",
        },
        {
          text: "Customer",
          value: "Customer",
        },
        // {
        //   text: "Amount",
        //   value: "Amount",
        // },
        {
          text: "Posting Date",
          value: "Date",
        },
        {
          text: "Note",
          value: "Note",
        },
      ],
    };
  },
  methods: {
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.params = {
        ...params,
        territory_ids,
        status: 6,
        // type: "Claim",
      };
      this.$vs.loading();

      const salesReturn = dataTableCreditNote(this.params);
      salesReturn.then((r) => {
        if (r.code == 500) {
          this.$vs.lFreloadoading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          //   this.data = r.data.creditNote;
          this.responseData = r.data;
          //   this.responseData.length = r.data.length;
          //   this.responseData.recordsTotal = r.data.recordsTotal;
          //   this.salesReturn = r.data.salesReturn;
          //   this.salesReturnType = r.data.salesReturnType;
          //   this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handleShow(tr) {
      this.selectedData = tr;
      this.showApprovalPrompt = true;
    },
    handleClose() {
      this.selectedData = false;
      this.showApprovalPrompt = false;
    },
    closeDetail() {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {};
      this.reloadData(this.params);
    },
    showDetail(tr) {
      this.detail = true;
      this.selectedData = tr;
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
