<template>
  <div class="flex flex-col gap-4 mt-4">
    <div class="flex gap-3">
      <vs-button @click="handleShow">Submit</vs-button>
    </div>
    <div class="vx-row">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <vs-th>
              <vs-checkbox class="w-full" v-model="approveAll">
                Approve All
              </vs-checkbox>
            </vs-th>
            <vs-th>
              <vs-checkbox class="w-full" v-model="rejectAll">
                Reject All
              </vs-checkbox>
            </vs-th>
          </template>
          <template slot="tbody">
            <vs-tr v-for="(tr, i) in responseData.creditNote" :key="i">
              <vs-td
                ><vs-checkbox
                  @click="addCheck(tr.ID, 'approve')"
                  :checked="isChecked(tr.ID, 'approve')"
              /></vs-td>
              <vs-td
                ><vs-checkbox
                  @click="addCheck(tr.ID, 'reject')"
                  :checked="isChecked(tr.ID, 'reject')"
              /></vs-td>
              <vs-td>
                <div class="flex gap-3">
                  <!-- <vx-tooltip style="width: fit-content" text="Approve Reversal">
                  <vs-button
                    color="danger"
                    type="line"
                    icon-pack="feather"
                    icon="icon-check"
                    @click="handleShow(tr)"
                  />
                </vx-tooltip>
                <vx-tooltip style="width: fit-content" text="Reject Reversal">
                  <vs-button
                    color="primary"
                    type="line"
                    icon-pack="feather"
                    icon="icon-x"
                    @click="handleShowReject(tr)"
                  />
                </vx-tooltip> -->
                  <vx-tooltip style="width: fit-content" text="Detail">
                    <vs-button
                      color="green"
                      type="line"
                      icon-pack="feather"
                      icon="icon-eye"
                      @click="showDetail(tr)"
                    />
                  </vx-tooltip>
                </div>
              </vs-td>
              <vs-td>{{ tr.Code }}</vs-td>
              <vs-td>{{ tr.Type }}</vs-td>
              <vs-td>
                Name : {{ tr.CustomerName }} <br />
                Code : {{ tr.CustomerCode }}
              </vs-td>
              <!-- <vs-td>{{ priceFormat(tr.TotalValue) }}</vs-td> -->
              <vs-td>{{ dateFormat(tr.PostingDate) }}</vs-td>
              <vs-td>{{ tr.NoteReversal }}</vs-td>
            </vs-tr>
          </template>
        </data-table>
        <vs-prompt
          title="Confirmation"
          color="danger"
          @accept="handleSubmit"
          @cancel="handleClose"
          @close="handleClose"
          :buttons-hidden="false"
          :active.sync="showApprovalPrompt"
        >
          <div class="flex flex-col gap-3">
            <p class="font-bold">Are you sure you want to submit?</p>
            <div class="flex flex-col gap-1">
              <span>Reversal date</span>
              <datepicker
                class="w-full"
                v-validate="'required'"
                :inline="false"
                v-model="reversalDate"
                placeholder="Date Clearing"
                name="reversal date"
                :disabled-dates="{ from: new Date() }"
              />
              <span
                v-show="errors.has('reversal date')"
                class="text-danger text-sm help is-danger"
              >
                {{ errors.first("reversal date") }}
              </span>
            </div>
          </div>
        </vs-prompt>
        <!-- <vs-prompt
        title="Confirmation"
        @accept="handleReject(selectedData)"
        @cancel="handleCloseReject"
        @close="handleCloseReject"
        :buttons-hidden="false"
        :active.sync="showRejectPrompt"
      >
        <div class="flex">
          <p>
            Are you sure you want to reject
            <span class="font-bold">{{ selectedData.Code }}</span>
          </p>
        </div>
      </vs-prompt> -->
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <detail @close="closeDetail" :selected="selectedData" />
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import detail from "../detail.vue";
import Datepicker from "vuejs-datepicker";
import { dataTableCreditNote } from "../../../../../services/api/credit_note";
export default {
  components: {
    detail,
    Datepicker,
  },
  data() {
    return {
      responseData: {},
      selectedData: {},
      reversalDate: new Date(),
      showApprovalPrompt: false,
      showRejectPrompt: false,
      params: {
        search: "",
        length: 5,
        page: 1,
        order: "desc",
        sort: "credit_notes.updated_at",
      },
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      header: [
        {
          text: "Action",
          align: "center",
          sortable: false,
        },
        {
          text: "Code",
          value: "Code",
        },
        {
          text: "Type",
          value: "Type",
        },
        {
          text: "Customer",
          value: "Customer",
        },
        // {
        //   text: "Amount",
        //   value: "Amount",
        // },
        {
          text: "Posting Date",
          value: "Date",
        },
        {
          text: "Note",
          value: "Note",
        },
      ],
      aprrovedCheckedID: [],
      rejectedCheckedID: [],
      approveAll: false,
      rejectAll: false,
    };
  },
  watch: {
    approveAll() {
      if (this.approveAll) {
        this.aprrovedCheckedID = this.responseData.creditNote.map(
          (item) => item.ID
        );

        this.rejectAll = false;
        this.rejectedCheckedID = [];
      } else {
        this.aprrovedCheckedID = [];
      }
    },
    rejectAll() {
      if (this.rejectAll) {
        this.rejectedCheckedID = this.responseData.creditNote.map(
          (item) => item.ID
        );

        this.approveAll = false;
        this.aprrovedCheckedID = [];
      } else {
        this.rejectedCheckedID = [];
      }
    },
  },
  methods: {
    reloadData(params) {
      const territory_ids =
        this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      this.params = {
        ...params,
        territory_ids,
        status: 5,
        // type: "Claim",
      };
      this.$vs.loading();

      const salesReturn = dataTableCreditNote(this.params);
      salesReturn.then((r) => {
        if (r.code == 500) {
          this.$vs.lFreloadoading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          //   this.data = r.data.creditNote;
          this.responseData = r.data;
          //   this.responseData.length = r.data.length;
          //   this.responseData.recordsTotal = r.data.recordsTotal;
          //   this.salesReturn = r.data.salesReturn;
          //   this.salesReturnType = r.data.salesReturnType;
          //   this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dateFormat(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    handleShow() {
      // this.selectedData = tr;
      if (
        this.aprrovedCheckedID.length === 0 &&
        this.rejectedCheckedID.length === 0
      ) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one data to be rejected or approved",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }

      this.showApprovalPrompt = true;
    },
    handleClose() {
      this.selectedData = false;
      this.showApprovalPrompt = false;
    },
    handleShowReject(tr) {
      this.selectedData = tr;
      this.showRejectPrompt = true;
    },
    handleCloseReject() {
      this.selectedData = false;
      this.showRejectPrompt = false;
    },
    handleReject(selected) {
      this.$vs.loading();
      this.$http
        .patch(`api/v1/credit-note/update-status/${selected.ID}`, {
          status: 4, //released
          reversal_date: moment().format("YYYY-MM-DD"),
        })
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();

          if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
    handleApprove(selected) {
      this.$vs.loading();
      this.$http
        .patch(`api/v1/credit-note/approval-matrix-reversal/${selected.ID}`, {
          status: 5,
          reversal_date: moment().format("YYYY-MM-DD"),
        })
        .then((result) => {
          console.log(result);
          this.$vs.loading.close();

          if (result.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Error",
              text: result.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.$vs.loading.close();
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: e.message,
            color: "danger",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-check",
          });
        });
    },
    handleSubmit() {
      // /credit-note/approve-reject
      this.$vs.loading();
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.$http
            .patch(`api/v1/credit-note/approve-reject`, {
              status: 5,
              reversal_date: moment(this.reversalDate).format("YYYY-MM-DD"),
              approved_credit_notes: this.aprrovedCheckedID,
              rejected_credit_notes: this.rejectedCheckedID,
            })
            .then((result) => {
              console.log(result);
              this.$vs.loading.close();

              if (result.code == 200) {
                this.$vs.notify({
                  title: "Success",
                  text: "",
                  color: "success",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.$vs.loading.close();
                this.aprrovedCheckedID = [];
                this.rejectedCheckedID = [];
                this.reloadData(this.params);
              } else {
                this.$vs.notify({
                  title: "Error",
                  text: result.message,
                  color: "danger",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-check",
                });
                this.$vs.loading.close();
              }
            })
            .catch((e) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error",
                text: e.message,
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
            });
        }
      });
    },
    closeDetail() {
      window.scrollTo(0, 0);
      this.detail = false;
      this.selectedData = {};
      this.reloadData(this.params);
    },
    showDetail(tr) {
      this.detail = true;
      this.selectedData = tr;
    },
    addCheck(id, type = "approve") {
      switch (type) {
        case "approve":
          if (this.aprrovedCheckedID.includes(id)) {
            const index = this.aprrovedCheckedID.indexOf(id);
            this.aprrovedCheckedID.splice(index, 1);
          } else {
            this.aprrovedCheckedID.push(id);
            if (this.rejectedCheckedID.includes(id)) {
              const index = this.rejectedCheckedID.indexOf(id);
              this.rejectedCheckedID.splice(index, 1);
            }
          }
          break;
        case "reject":
          if (this.rejectedCheckedID.includes(id)) {
            const index = this.rejectedCheckedID.indexOf(id);
            this.rejectedCheckedID.splice(index, 1);
          } else {
            this.rejectedCheckedID.push(id);
            if (this.aprrovedCheckedID.includes(id)) {
              const index = this.aprrovedCheckedID.indexOf(id);
              this.aprrovedCheckedID.splice(index, 1);
            }
          }
          break;
      }
    },
    isChecked(id, type = "approve") {
      if (type == "approve") {
        return this.aprrovedCheckedID.includes(id);
      } else {
        return this.rejectedCheckedID.includes(id);
      }
    },
  },
};
</script>
<style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>
